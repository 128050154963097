import { render, staticRenderFns } from "./MTPLInjuredDataTable.vue?vue&type=template&id=3fba0687&"
import script from "./MTPLInjuredDataTable.vue?vue&type=script&lang=js&"
export * from "./MTPLInjuredDataTable.vue?vue&type=script&lang=js&"
import style0 from "./MTPLInjuredDataTable.vue?vue&type=style&index=0&id=3fba0687&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports